.container {
  display: flex;
}
.container button {
  cursor: pointer;
}
.book-navbar-container {
  width: 15em;
  max-height: 100vh;
  overflow-y: scroll;
}

.book-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  height: 100vh;
  gap: 0;
}
.div-title {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-height: min-content;
  height: 4em;
}
.div-title button {
  height: fit-content;
  margin: auto 0;
  padding: 1em 2em;
  border-radius: 1em;
  border: solid 1px #cccc;
}

.book-container h2 {
  text-align: center;
}
.div-body {
  height: 100%;
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.div-img {
  max-height: 85vh;
  width: fit-content;
  border: solid 2px #ccc;
  position: relative;
}

.div-img img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
.div-img button {
  position: absolute;
  top: 45%;
  font-size: 4em;
  border: none;
  display: flex;
  background-color: transparent;
}
.btn-left {
  left: -0.5em;
}
.btn-right {
  right: -0.5em;
}
.sections {
  background-color: black;
  max-height: 100%;
  overflow-y: scroll;
  height: 100%;
  display: block;
}
.section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: blue;
  gap: 1;

  min-height: fit-content !important;
  margin: 1em;
  overflow-y: visible;
}
.section textarea {
  width: 90%;
  height: 5em !important;
  min-height: fit-content !important;
  font-size: 1.2em;
  border: none;
  background-color: red;
  margin: auto;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
}
.buttons button {
  font-weight: 900;
}
.btn-clean {
  background-color: #22bb45;
  color: white;
}
.btn-error {
  background-color: #bb2200;
  color: white;
}
