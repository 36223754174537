.box-container {
  background-color: white;
  box-shadow: #ccc 1px 1px;
  width: 50vw;
  margin: auto;
  margin-top: 1em;
  border: solid 2px #ccc;
  padding: 3em;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1em;
}
.blocks {
  display: flex;
  justify-content: center;
  gap: 1em;
  height: fit-content;

  padding: 0em;
  margin: 0;
  margin: auto;
}

.letter {
  background-color: #eee;
  font-size: 1.8em;
  line-height: 1.8em;
  border-radius: 0.2em;
  font-weight: 900;
  color: white;
  text-shadow: #555 1px 1px;
  border: solid 3px #999;
  border-radius: 0.3em;
  width: 1.6em;
  height: 1.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0;
}
.letter-gray {
  background-color: #555;
}
.letter-yellow {
  background-color: #f3c237 !important;
}
.letter-green {
  background-color: #79b851 !important;
}
