.login-container {
  width: 40vw;
  min-height: 40vh;
  padding: 5vh 10vw;
  margin: auto;
  margin-top: 20vh;
  background-color: white;
  border: solid 1px #cccc;
}
.login-container form {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.login-container form input,
.login-container form button {
  padding: 1em;
}
